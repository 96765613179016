import { ContainerModuleLoader, withDependencies } from '@wix/thunderbolt-ioc'
import {
	LoggerSymbol,
	ILogger,
	Interaction,
	Phase,
	LoggerIntegrations,
	IRendererPropsExtender,
	RendererPropsExtenderSym,
} from '@wix/thunderbolt-symbols'
import { Environment } from '../../types/Environment'
import { IStartInteractionOptions, IEndInteractionOptions } from '@wix/fedops-logger'
import {
	commonBiLoggerFactory,
	createFedopsLogger,
	getBiStore,
	createConsoleLogger,
	addTagsFromObject,
	extractFingerprints,
	getEnvironment,
	extractFileNameFromErrorStack,
	shouldFilter,
} from '@wix/thunderbolt-commons'

export function createLogger(loggerIntegrations: LoggerIntegrations): ILogger {
	let sessionErrorLimit = 50
	const ongoingfedops = {
		interactions: 'none',
		phase: 'none',
	}
	if (process.env.browser) {
		window.fedops.ongoingfedops = ongoingfedops
	}
	const { sentry, wixBiSession, viewerModel, fetch } = loggerIntegrations
	const mode = viewerModel && viewerModel.mode ? viewerModel.mode : { qa: true }
	if (mode.qa || !sentry) {
		return createConsoleLogger()
	}

	const biStore = getBiStore(wixBiSession, viewerModel)
	const biLoggerFactory = commonBiLoggerFactory.createBiLoggerFactoryForFedops({
		biStore,
		fetch,
		muteBi: viewerModel.requestUrl.includes('suppressbi=true'),
	})
	const fedopsLogger = createFedopsLogger({
		biLoggerFactory,
		phasesConfig: 'SEND_ON_START',
		appName: viewerModel.site && viewerModel.site.isResponsive ? 'thunderbolt-responsive' : 'thunderbolt',
		reportBlackbox: true,
		paramsOverrides: { is_rollout: biStore.is_rollout },
	})

	const getInstance = (forceLoad: boolean = false) => {
		if (!process.env.browser) {
			return sentry
		}
		if (forceLoad) {
			// @ts-ignore no type for sentry loader
			window.Sentry.forceLoad()
		}
		return window.Sentry
	}

	getInstance().configureScope((scope: any) => {
		scope.addEventProcessor((event: any) => {
			if (biStore.isCached || wixBiSession.isjp) {
				return null
			}
			event.release = process.env.browser ? window.thunderboltVersion : process.env.APP_VERSION
			event.environment = getEnvironment(viewerModel.fleetConfig.code)
			if (event.level === 'error') {
				fedopsLogger.interactionStarted('error', { customParams: { errorMessage: event.message } }) // this is a workaround to get error rate until we will have support for postgresSQL in fedonomy
			}
			if (!event.fingerprint) {
				const fingerprints = extractFingerprints(event.exception)
				event.fingerprint = [...fingerprints]
			}
			if (sessionErrorLimit) {
				sessionErrorLimit--
				return event
			}
			return null
		})
		scope.setUser({ viewerSessionId: wixBiSession.viewerSessionId })
		scope.setExtra('experiments', viewerModel.experiments)
		addTagsFromObject(scope, {
			url: viewerModel.requestUrl,
			isSsr: !process.env.browser,
			...ongoingfedops,
			...viewerModel.deviceInfo,
		})
	})

	const getErrorThatDoesNotFreezeBrowser = (error: Error) => {
		// long stacks freeze the browser during some sentry internal calculation.
		// somewhere here: https://github.com/getsentry/sentry-javascript/blob/668f44ffdb068cd2d0f89085e50c9d1b4dd38295/packages/browser/src/tracekit.ts#L186
		// this is internal crap that can't be unit tested.
		if (!error.stack || error.stack.length <= 2000) {
			return error
		}

		const { name, message, stack } = error
		const errorThatDoesNotFreezeBrowser = new (error.constructor as ErrorConstructor)(message)
		errorThatDoesNotFreezeBrowser.name = name
		errorThatDoesNotFreezeBrowser.stack = `${stack.substring(0, 1000)}\n...\n${stack.substring(
			stack.length - 1000
		)}`
		return errorThatDoesNotFreezeBrowser
	}

	const captureError = (
		error: Error,
		{
			tags,
			extra,
			groupErrorsBy = 'tags',
			level = 'error',
		}: {
			tags: { [key: string]: string | boolean }
			extra?: { [key: string]: any }
			groupErrorsBy?: 'tags' | 'values'
			level?: string
		}
	) =>
		getInstance(true).withScope((scope: any) => {
			const fingerprints = []
			scope.setLevel(level)
			for (const key in tags) {
				if (tags.hasOwnProperty(key)) {
					scope.setTag(key, tags[key])
					if (groupErrorsBy === 'tags') {
						fingerprints.push(key)
					} else if (groupErrorsBy === 'values') {
						fingerprints.push(tags[key])
					}
				}
			}

			for (const key in extra) {
				if (extra.hasOwnProperty(key)) {
					scope.setExtra(key, extra[key])
				}
			}

			const fileName = error.stack ? extractFileNameFromErrorStack(error.stack) : 'unknownFile'
			scope.setExtra('_fileName', fileName)
			scope.setFingerprint([error.message, fileName, ...fingerprints])

			if (sessionErrorLimit) {
				getInstance().captureException(getErrorThatDoesNotFreezeBrowser(error))
			}
			console.log(error) // Sentry capture exception swallows the error
		})
	const phaseMark = (phase: Phase) => {
		ongoingfedops.phase = phase
		getInstance().addBreadcrumb({ message: 'phase:' + phase })
		fedopsLogger.appLoadingPhaseStart(phase)
	}
	const interactionStarted = (interaction: Interaction, interactionOptions?: Partial<IStartInteractionOptions>) => {
		ongoingfedops.interactions =
			ongoingfedops.interactions === 'none' ? interaction : ongoingfedops.interactions + interaction
		getInstance().addBreadcrumb({ message: 'interaction start: ' + interaction })
		fedopsLogger.interactionStarted(interaction, interactionOptions || {})
	}
	const interactionEnded = (interaction: Interaction, interactionOptions?: Partial<IEndInteractionOptions>) => {
		ongoingfedops.interactions =
			ongoingfedops.interactions === interaction ? 'none' : ongoingfedops.interactions.replace(interaction, '')
		getInstance().addBreadcrumb({ message: 'interaction end: ' + interaction })
		fedopsLogger.interactionEnded(interaction, interactionOptions || {})
	}
	const meter = (metricName: string, interactionOptions?: Partial<IStartInteractionOptions>) => {
		getInstance().addBreadcrumb({ message: 'meter: ' + metricName })
		fedopsLogger.interactionStarted(metricName, interactionOptions || {})
	}
	if (process.env.browser) {
		window.fedops.phaseMark = phaseMark
	}

	return {
		reportAsyncWithCustomKey: <T>(asyncMethod: () => Promise<T>, methodName: string, key: string): Promise<T> => {
			// @ts-ignore FEDINF-1937 missing type
			interactionStarted(methodName, { customParam: { key } })
			return asyncMethod()
				.then(
					(res): Promise<T> => {
						// @ts-ignore FEDINF-1937 missing type
						interactionEnded(methodName, { customParam: { key } })
						return Promise.resolve(res)
					}
				)
				.catch((error) => {
					captureError(error, { tags: { methodName } })
					return Promise.reject(error)
				})
		},
		runAsyncAndReport: async <T>(
			asyncMethod: () => Promise<T>,
			methodName: string,
			reportExeception: boolean = true
		): Promise<T> => {
			try {
				interactionStarted(`${methodName}`)
				const fnResult = await asyncMethod()
				interactionEnded(`${methodName}`)
				return fnResult
			} catch (e) {
				if (reportExeception) {
					captureError(e, { tags: { methodName } })
				}
				throw e
			}
		},
		runAndReport: <T>(method: () => T, methodName: string): T => {
			interactionStarted(methodName)
			try {
				const t = method()
				interactionEnded(methodName)
				return t
			} catch (e) {
				captureError(e, { tags: { methodName } })
				throw e
			}
		},
		captureError,
		setGlobalsForErrors: ({ tags, extra, groupErrorsBy } = { groupErrorsBy: 'tags' }) =>
			getInstance().configureScope((scope: any) => {
				scope.addEventProcessor((event: any, hint: any) => {
					const { message } = hint.originalException
					const fingerprints = []

					if (shouldFilter(message)) {
						return null
					}

					for (const key in tags) {
						if (tags.hasOwnProperty(key)) {
							if (groupErrorsBy === 'tags') {
								fingerprints.push(key)
							} else if (groupErrorsBy === 'values') {
								fingerprints.push(tags[key])
							}
						}
					}

					if (extra) {
						event.extra = event.extra || {}
						Object.assign(event.extra, extra)
					}

					if (tags) {
						event.tags = event.tags || {}
						Object.assign(event.tags, tags)
					}

					if (fingerprints.length) {
						scope.setFingerprint(['{{ default }}', ...fingerprints])
					}

					return event
				})
			}),
		breadcrumb: (messageContent, additionalData = {}) =>
			getInstance().addBreadcrumb({
				message: messageContent,
				data: additionalData,
			}),
		interactionStarted,
		interactionEnded,
		phaseMark,
		meter,
		reportAppLoadStarted: () => fedopsLogger.appLoadStarted(),
		appLoaded: () => {
			ongoingfedops.phase = 'siteLoaded'
			window.onoffline = () => {}
			window.ononline = () => {}
			removeEventListener('beforeunload', window.fedops.beforeunload)
			removeEventListener('pagehide', window.fedops.pagehide)
			fedopsLogger.appLoaded()
		},
	}
}

const rendererPropsExtender = withDependencies(
	[LoggerSymbol],
	(logger: ILogger): IRendererPropsExtender => {
		return {
			async extendRendererProps() {
				return { logger }
			},
		}
	}
)

export const site = ({ logger }: Environment): ContainerModuleLoader => (bind) => {
	bind(LoggerSymbol).toConstantValue(logger)
	bind(RendererPropsExtenderSym).to(rendererPropsExtender)
}
